import React, { Component, Suspense } from 'react'
//import { Router, Route, Routes, Navigate } from 'react-router-dom';
import { CSpinner } from '@coreui/react';
import { HashRouter, Route, Routes, Navigate } from "react-router-dom";
import './scss/style.scss';

const loading = (
  <div className="pt-3 text-center">
    <CSpinner color="primary" />
  </div>
)

// Containers
const TheLayout = React.lazy(() => import('./containers/TheLayout'));

// Pages
const Register = React.lazy(() => import('./views/pages/register/Register'));
const Reset = React.lazy(() => import('./views/pages/reset/Reset'));

class App extends Component {
  render() {
    return (
      <HashRouter>
        <Suspense fallback={loading}>
          <Routes>
            <Route path="*" name="Tiipe" element={(props => <TheLayout {...props}/>)()} />
            <Route exact path="/register" name="Register" element={(props => <Register {...props}/>)()} />
            <Route exact path="/reset" name="Reset password" element={(props => <Reset {...props}/>)()} />
            <Route path="/" element={<Navigate replace to="/create" />} />
          </Routes>
        </Suspense>
      </HashRouter>
    )
  }
}

export default App;
